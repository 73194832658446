import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "./gallery-slider.css"

const GallerySlider = () => {
  return (
    <div className="gallery-slider">
      <div className="gallery-slider__slides">
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-1.jpg"/>
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-2.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-3.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-4.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-5.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-6.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-7.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-8.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-9.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-10.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-11.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-12.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-13.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-14.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-15.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-16.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-17.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/gallery-blueacrossri-18.jpg" />
      </div>
      <div className="gallery-slider__cta">
        Scroll
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path d="M12 8c0 .2-.1.4-.2.5l-4.4 5.2c-.3.3-.8.4-1.1.1-.3-.2-.4-.7-.1-1l4-4.7-4-4.8c-.3-.4-.2-.8.1-1.1.3-.3.8-.2 1.1.1l4.4 5.2c.1.1.2.3.2.5z" fill="currentColor" />
        </svg>
      </div>
    </div>
  );
}

export default GallerySlider
