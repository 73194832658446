import React from "react";
import PropTypes from "prop-types";
import "./row.css";
/*
  Usage – can be passed a reverse prop to swap the columns.
  Can be passed a doubleGap prop to double the gap width.  Standard is 3.75rem (60px)
  Use with NO MORE THAN TWO direct children, which can contain text, headings,
  images, etc.
  In most cases, Row components will be placed inside a NestedContainer
  component.

  <Row6633
    reverse
    doubleGap
    verticalCenter
  >
    <div><h1>here's some content</h1></div>
    <div><h1>here's some content</h1></div>
  </Row6633>

*/

const Row6633 = ({ reverse, doubleGap, verticalCenter, children }) => (
  <div
    className={`flex-row flex-row--${reverse ? "66-33--rev" : "66-33"} flex-row--${
      verticalCenter ? "vertical-center" : "normal"} ${
      doubleGap ? "gap--double" : "gap--standard"}
    `}
  >
    {children}
  </div>
);

Row6633.defaultProps = {
  reverse: false,
  doubleGap: false,
  verticalCenter: false,
};

Row6633.propTypes = {
  reverse: PropTypes.bool,
  doubleGap: PropTypes.bool,
  verticalCenter: PropTypes.bool,
};

export default Row6633;
