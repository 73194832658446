import * as React from "react";
import PropTypes from "prop-types";
import "./section-header.css";

/*
  Usage – must include the following params when called.
  - reportType: a string containing the report type keyword.
      Used to control eyebrow color.
  - title: A string for the title.
  - intro: A string for the intro paragraph.
  - intro2: A string for the second intro paragraph.
  - dark: A boolean to pass in when used on a dark background.

  Example:
  <SectionHeader
    reportType={"community"}
    title={"By the Numbers"}
    intro={"Lipsum..."}
    intro2={"Lipsum..."}
  />
*/

const SectionHeader = ({
  reportType,
  title,
  intro,
  intro2,
  dark,
  small,
  center,
}) => {
  const reportTypeNoSpace = reportType.replace(/\s+/g, '-');

  return (
    <>
      <header
        className={`section-header section-header--${
          dark ? "dark" : "light"
        } section-header--${small ? "small" : ""} ${
          center ? "section-header--center" : ""
        }`}
      >
        <span
          className={`section-header__report-type section-header__report-type--${reportTypeNoSpace}`}
        >
          {reportType}
        </span>
        {small ? <h3>{title}</h3> : <h2>{title}</h2>}
        <p>{intro}</p>
        {!!intro2 && <p>{intro2}</p>}
      </header>
    </>
  );
};

SectionHeader.defaultProps = {
  reportType: "",
  title: "",
  intro: "",
  intro2: "",
  dark: false,
  small: false,
  center: false,
};

SectionHeader.propTypes = {
  reportType: PropTypes.string,
  title: PropTypes.string,
  intro: PropTypes.string,
  intro2: PropTypes.string,
  dark: PropTypes.bool,
  small: PropTypes.bool,
  center: PropTypes.bool,
};

export default SectionHeader;
