import * as React from "react";
import "./stat.css";

const StatContainer = ({ identifier, children }) => {
  return (
    <div
      className={`stat-container stat-container-${
        identifier ? identifier : ""
      }`}
    >
      {children}
    </div>
  );
};

export default StatContainer;
