import React, { useState, useRef } from "react";

// Global Layout
import Layout from "../components/layout/layout";

// Components
import Hero from "../components/hero/hero";
import FullWidthContainer from "../components/full-width-container/full-width-container";
import NestedContainer from "../components/nested-container/nested-container";
import Row6633 from "../components/row/row-66-33";
import RowEqual from "../components/row/row-equal";
import SectionHeader from "../components/section-header/section-header";
import { StaticImage } from "gatsby-plugin-image";
import TestimonialCallout from "../components/testimonial-callout/testimonial-callout";
import GallerySlider from "../components/gallery-slider/gallery-slider";
import VideoModal from "../components/video-modal/video-modal";
import StatContainer from "../components/stat/stat-container";
import Stat from "../components/stat/stat";

// Images
import playIcon from "../images/community/Icon-Play-Video-Green.svg";
import posterImageTenYears from "../images/community/Video-BlueAcross-RI.jpg";
import amosHouseMuted from "../images/community/Logo-Amos-House.jpg";
import amosHouseColor from "../images/community/Logo-Amos-House_COLOR.jpg";
import childFamilyMuted from "../images/community/Logo-Child-and-Family.jpg";
import childFamilyColor from "../images/community/Logo-Child-and-Family_COLOR.jpg";
import fosterForwardMuted from "../images/community/Logo-Foster-Forward.jpg";
import fosterForwardColor from "../images/community/Logo-Foster-Forward_COLOR.jpg";
import lucysHearthMuted from "../images/community/Logo-Lucys-Hearth.jpg";
import lucysHearthColor from "../images/community/Logo-Lucys-Hearth_COLOR.jpg";
import openDoorsMuted from "../images/community/Logo-Open-Doors.jpg";
import openDoorsColor from "../images/community/Logo-Open-Doors_COLOR.jpg";
import phaMuted from "../images/community/Logo-PHA.jpg";
import phaColor from "../images/community/Logo-PHA_COLOR.jpg";
import centerForJusticeMuted from "../images/community/Logo-Center-for-Justice.jpg";
import centerForJusticeColor from "../images/community/Logo-Center-for-Justice_COLOR.jpg";
import riLegalServicesMuted from "../images/community/Logo-RI-Legal-Services.jpg";
import riLegalServicesColor from "../images/community/Logo-RI-Legal-Services_COLOR.jpg";
import sojournerHouseMuted from "../images/community/Logo-Sojourner-House.jpg";
import sojournerHouseColor from "../images/community/Logo-Sojourner-House_COLOR.jpg";

//Stylesheets
import "./community.css";

const CommunityPage = () => {
  const imageRefAmos = useRef(null);
  const imageRefChild = useRef(null);
  const imageRefFoster = useRef(null);
  const imageRefLucy = useRef(null);
  const imageRefOpen = useRef(null);
  const imageRefPha = useRef(null);
  const imageRefJustice = useRef(null);
  const imageRefLegal = useRef(null);
  const imageRefSojourner = useRef(null);

  return (
    <Layout pageTitle="2021 BCBSRI Mission Report – Community">
      <div className="community-page">
        <FullWidthContainer singleHero background={`var(--white-to-blue)`}>
          <Hero
            title="Community"
            intro="Health is much more than what happens at the doctor’s office. Our well-being is influenced by where we grow up, play, live, and work. That knowledge drives us to help our families and friends, neighbors and strangers build stronger, healthier communities."
            reportType="community"
            inner
            customers
            partners
          >
            <StaticImage
              src="../images/community/Community-Hero.jpg"
              alt="Smiling mother and son"
              layout="fullWidth"
            />
          </Hero>
        </FullWidthContainer>
        <div id="learning-where-we-can-help">
          <FullWidthContainer
            styleName="community-intro-block"
            background={`var(--blue-to-white)`}
          >
            <SectionHeader
              reportType={"community"}
              title={"Learning where we can help"}
              intro={
                "In 2019, we partnered with the Brown University School of Public Health to launch the RI Life Index. This annual statewide assessment captures Rhode Islanders’ perceptions of social determinants of health and well-being, such as education and housing. Amid the COVID-19 pandemic, factors around healthcare became even more significant as it became more difficult—or people became less confident—to get the care they need."
              }
            />
            <NestedContainer padded paddedLast className="community-learning">
              <Row6633 doubleGap>
                <div>
                  <StaticImage
                    src="../images/community/Illustration-LifeIndex-BrownUniversity.png"
                    alt="people sharing"
                  />
                </div>
                <div className="community-learning__right">
                  <p>
                    In 2021, for the first time, we had the data to report on
                    trends occurring in our community between 2019 and 2021.
                    This data demonstrated that BIPOC (Black, indigenous, and
                    people of color) Rhode Islanders living in communities with
                    the highest rates of poverty saw affordable housing and cost
                    of living to be much greater barriers to their health and
                    well-being than did white Rhode Islanders living in other
                    areas.
                  </p>
                  <p>
                    Behind these numbers are our fellow Rhode Islanders, and
                    we’ve focused our charitable giving on programs that address
                    the problems they face, particularly the intersection of
                    housing and health.
                  </p>
                  <a
                    className="community-link"
                    href="https://www.rilifeindex.org"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn how our neighbors see their world
                  </a>
                </div>
              </Row6633>
              <div style={{ marginTop: "3rem" }}>
                <TestimonialCallout
                  quote="This survey tells us a lot about the barriers the people of Rhode Island face every day to achieving health, especially people of color and people living in lower socioeconomic communities."
                  author="Dr. Ashish Jha"
                  role="Dean, Brown University School of Public Health"
                />
              </div>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="helping-our-neighbors-find-homes">
          <FullWidthContainer styleName="" background="#F7F6F2">
            <SectionHeader
              reportType={"community"}
              title={"Helping our neighbors find homes"}
              intro={
                "Even before the COVID-19 pandemic, too many Rhode Islanders faced housing insecurity and homelessness. The problem has become more urgent with the economic hardships the pandemic created. In 2021, we focused much of our community investment on organizations helping our neighbors find safe, affordable places to live."
              }
            />
            <NestedContainer
              className="community-grants"
              background={`var(--white)`}
              hasShadow
              padded
            >
              <Row6633 doubleGap>
                <div>
                  <div>
                    <StaticImage
                      src="../images/community/Image-Community-Health-Grants.jpg"
                      alt="Mother and children looking at a tablet screen"
                    />
                    <span className="img-caption">
                      Photo: Courtesy of Lucy's Hearth
                    </span>
                  </div>
                </div>
                <div className="community-grants__description">
                  <StaticImage
                    src="../images/community/Logo-BlueAngel.png"
                    alt="BlueAngel"
                    width="227"
                  />
                  <h3>Community Health Grants</h3>
                  <p>
                    Tens of thousands of Rhode Islanders, including families and
                    children, got help with housing through the nine
                    organizations that received BlueAngel Community Health
                    Grants from BCBSRI in 2021.
                  </p>
                  <p>
                    These grants, the cornerstone of our community investment
                    program, fund programs that find housing for families,
                    foster youth, and victims of domestic violence as well as
                    programs that provide legal assistance.
                  </p>
                </div>
              </Row6633>

              <Row6633 doubleGap>
                <div className="community-grants__recipients mt-sm">
                  <p className="community-subheader h3--btm-border">
                    2021 blueangel community health grant recipients
                  </p>
                  <div class="community-grants__recipients__grid">
                    <a
                      href="https://amoshouse.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        onMouseOver={() => {
                          imageRefAmos.current.src = amosHouseColor;
                        }}
                        onMouseOut={() => {
                          imageRefAmos.current.src = amosHouseMuted;
                        }}
                        src={amosHouseMuted}
                        alt="Amos House"
                        ref={imageRefAmos}
                      />
                    </a>
                    <a
                      href="https://childandfamilyri.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        onMouseOver={() => {
                          imageRefChild.current.src = childFamilyColor;
                        }}
                        onMouseOut={() => {
                          imageRefChild.current.src = childFamilyMuted;
                        }}
                        src={childFamilyMuted}
                        alt="Child & Family"
                        ref={imageRefChild}
                      />
                    </a>
                    <a
                      href="https://www.fosterforward.net"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        onMouseOver={() => {
                          imageRefFoster.current.src = fosterForwardColor;
                        }}
                        onMouseOut={() => {
                          imageRefFoster.current.src = fosterForwardMuted;
                        }}
                        src={fosterForwardMuted}
                        alt="Foster Forward"
                        ref={imageRefFoster}
                      />
                    </a>
                    <a
                      href="https://www.lucyshearth.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        onMouseOver={() => {
                          imageRefLucy.current.src = lucysHearthColor;
                        }}
                        onMouseOut={() => {
                          imageRefLucy.current.src = lucysHearthMuted;
                        }}
                        src={lucysHearthMuted}
                        alt="Lucy's Hearth"
                        ref={imageRefLucy}
                      />
                    </a>
                    <a
                      href="http://www.opendoorsri.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        onMouseOver={() => {
                          imageRefOpen.current.src = openDoorsColor;
                        }}
                        onMouseOut={() => {
                          imageRefOpen.current.src = openDoorsMuted;
                        }}
                        src={openDoorsMuted}
                        alt="Open Doors Rhode Island"
                        ref={imageRefOpen}
                      />
                    </a>
                    <a
                      href="https://provhousing.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        onMouseOver={() => {
                          imageRefPha.current.src = phaColor;
                        }}
                        onMouseOut={() => {
                          imageRefPha.current.src = phaMuted;
                        }}
                        src={phaMuted}
                        alt="Providence PHA Housing Authority"
                        ref={imageRefPha}
                      />
                    </a>
                    <a
                      href="https://centerforjustice.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        onMouseOver={() => {
                          imageRefJustice.current.src = centerForJusticeColor;
                        }}
                        onMouseOut={() => {
                          imageRefJustice.current.src = centerForJusticeMuted;
                        }}
                        src={centerForJusticeMuted}
                        alt="Center for Justice"
                        ref={imageRefJustice}
                      />
                    </a>
                    <a
                      href="https://www.helprilaw.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        onMouseOver={() => {
                          imageRefLegal.current.src = riLegalServicesColor;
                        }}
                        onMouseOut={() => {
                          imageRefLegal.current.src = riLegalServicesMuted;
                        }}
                        src={riLegalServicesMuted}
                        alt="Rhode Island Legal Services"
                        ref={imageRefLegal}
                      />
                    </a>
                    <a
                      href="https://sojournerri.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        onMouseOver={() => {
                          imageRefSojourner.current.src = sojournerHouseColor;
                        }}
                        onMouseOut={() => {
                          imageRefSojourner.current.src = sojournerHouseMuted;
                        }}
                        src={sojournerHouseMuted}
                        alt="Sojourner House"
                        ref={imageRefSojourner}
                      />
                    </a>
                  </div>
                </div>
                <div>
                  <StatContainer>
                    <Stat
                      stat={"500,000"}
                      dollarSign
                      plusIcon
                      description={"invested in housing initiatives"}
                    />
                    <Stat
                      stat={"23,000"}
                      plusIcon
                      description={"Rhode Islanders helped"}
                    />
                  </StatContainer>
                </div>
              </Row6633>
            </NestedContainer>
            <NestedContainer
              styleName=""
              className="safer-homes"
              border={`thick-white`}
              background={`var(--color-19)`}
              hasShadow
            >
              <div className="community-process-testimonial">
                <Row6633 reverse verticalCenter>
                  <div>
                    <StaticImage
                      src="../images/community/Image-Safer-Homes.jpg"
                      alt="Smiling elderly couple."
                    />
                    <span className="img-caption">
                      Photo: &copy;Habitat for Humanity
                    </span>
                  </div>
                  <div>
                    <h3>Safer homes for aging adults</h3>
                    <p>
                      Many Rhode Islanders want to remain in their own homes as
                      they age. But if older homeowners can’t adapt and maintain
                      their homes, it can be difficult to live safely and
                      comfortably. This year, BCBSRI supported the launch of
                      Habitat for Humanity of Greater Providence’s new Aging in
                      Place Program. Through this program, older Rhode Islanders
                      are now receiving home modification, repair, and
                      decluttering services almost daily, ensuring their homes
                      will be safer and more affordable places to remain
                      long-term.
                    </p>

                    <figure className="testimonial">
                      <span className="testimonial__border"></span>
                      <blockquote>
                        “We could not be more proud to be considered partners
                        with Blue Cross in making a difference in people’s
                        lives.”
                      </blockquote>
                      <figcaption>
                        <cite>&mdash;&nbsp;Mark Kravatz</cite>, Executive
                        Director, Habitat for Humanity of Greater Providence
                      </figcaption>
                    </figure>
                  </div>
                </Row6633>
              </div>
            </NestedContainer>
            <NestedContainer
              padded
              paddedLast
              className="community-housing"
              styleName=""
            >
              <Row6633 doubleGap>
                <div>
                  <StaticImage
                    src="../images/community/Icon-Housing.svg"
                    alt="house"
                  />
                  <h3>1,500 additional housing units by 2024</h3>
                  <div className="community-housing__additional-housing">
                    <p>
                      More Rhode Islanders will have a place to call home thanks
                      to a BCBSRI grant of $4 million to Local Initiatives
                      Support Corporation (LISC) over the next four years. This
                      investment is the largest single grant in BCBSRI history,
                      and it will support financing to produce and preserve
                      housing, provide down payment assistance, and bolster
                      community facilities.
                    </p>

                    <p>
                      Creating affordable housing requires significant
                      investment, and this grant will serve as an anchor to
                      leverage additional support and build a larger fund over
                      time. It will allow LISC RI to do more, in more innovative
                      ways, more quickly. Additionally, this investment
                      illustrates the link between housing and health and the
                      need to address these issues cooperatively.
                    </p>
                  </div>
                  <div className="community-housing__action-plan">
                    <StaticImage
                      src="../images/community/Icon-Senior-Housing.svg"
                      alt="house"
                    />
                    <h3>Action plan for housing</h3>
                    <p>
                      The 2021 Housing Fact Book from HousingWorks RI explores
                      housing’s intersections with health, education, and the
                      economy. With support from BCBSRI, HousingWorks is
                      developing an action plan for the next decade of housing
                      for older Rhode Islanders, providing a blueprint for
                      advocates to promote change in local and state laws and
                      regulations to address these needs.
                    </p>
                    <a
                      class="community-link"
                      href="https://www.housingworksri.org/Portals/0/Uploads/Documents/FINALHFB21-revised_2021-11-02.pdf?ver=s3DdsTMeA07Fq-D28P9x1A%3d%3d"
                      target="_blank"
                      rel="noreferrer"
                    >
                      See the 2021 Housingworks RI Fact Book
                    </a>
                  </div>
                </div>
                <div>
                  <StaticImage
                    src="../images/community/Icon-Childrens-Housing.svg"
                    alt="house"
                  />
                  <h3>Recommendations on children’s housing instability</h3>
                  <p>
                    Poor quality, unaffordable, or crowded housing can damage a
                    child’s physical health, development, and emotional
                    well-being. BCBSRI funded a 2021 Policy Brief from Rhode
                    Island KIDS COUNT that highlights and explains the issue and
                    offers recommendations for supporting children and families.
                  </p>
                  <a
                    class="community-link"
                    href="https://www.rikidscount.org/Portals/0/Uploads/Documents/Special%20Publications/11.30.21%206795_Housing_Homeless_v6%20final.pdf?ver=2021-11-30-151400-427"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read the recommendations
                  </a>
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="celebrating-a-decade-of-dedication">
          <FullWidthContainer styleName="" background={`var(--white)`}>
            <SectionHeader
              reportType={"community"}
              title={"Celebrating a decade of dedication"}
              intro={
                "With masks, hand sanitizer, safety procedures, and lots of enthusiasm, we held our 10th annual company-wide day of service—Blue across Rhode Island—on Friday, September 24. We partnered with 13 nonprofit organizations across the state that work to support the health and well-being of Rhode Islanders."
              }
              intro2={
                "447 employees built houses and play spaces, assembled blankets, painted a senior center, and packaged footwear for children experiencing homelessness. In addition to volunteer support, each project site received a financial contribution of $5,000 to assist with their important work."
              }
            />
            <NestedContainer
              styleName=""
              className="community-celebrate"
              background={`var(--color-20)`}
              padded
            >
              <Row6633 doubleGap>
                <div className="community-celebrate__left">
                  <VideoModal
                    ariaHideApp={false}
                    posterImage={posterImageTenYears}
                    videoPlayIcon={playIcon}
                    videoSource="https://player.vimeo.com/video/689110487?h=d102f5767d"
                  />
                  <figure className="testimonial">
                    <span className="testimonial__border"></span>
                    <blockquote>
                      “We are thrilled to say this is our 9th year of partnering
                      with Blue across Rhode Island. I just want to thank you
                      from the bottom of our hearts for being a part of this
                      community and helping make the world a better place.”
                    </blockquote>
                    <figcaption>
                      <cite>&mdash;&nbsp;Nicholas Lowinger</cite>, Founder,
                      Gotta Have Sole
                    </figcaption>
                  </figure>
                </div>
                <div className="community-celebrate__right">
                  <p className="community-subheader h3--btm-border">
                    10 Years of Blue Across <br /> Rhode Island
                  </p>
                  <StatContainer>
                    <Stat stat={"70"} description={"agencies served"} />
                    <Stat stat={"118"} description={"projects completed"} />
                    <Stat stat={"605,000"} dollarSign description={"donated"} />
                  </StatContainer>
                </div>
              </Row6633>
              <GallerySlider />
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="protecting-our-members-during-the-covid-19-pandemic">
          <FullWidthContainer styleName="" background={`var(--color-2)`}>
            <SectionHeader
              reportType={"community"}
              title={"Protecting members during the COVID-19 pandemic"}
              intro={
                "In the second year of COVID-19, as we all faced the ups and downs of the pandemic, we continued to support our members with temporary benefits that helped them access care—with no added worries about cost."
              }
              dark
            />
            <NestedContainer padded className="protecting-members">
              <Row6633 doubleGap>
                <div className="protecting-members__how">
                  <h3>How we encouraged members to get vaccinated</h3>
                  <StaticImage
                    src="../images/community/Image-Covid.png"
                    alt="Test tube"
                  />
                  <div className="protecting-members__how--columns">
                    <div>
                      <ul className="community-list">
                        <li>
                          Holding clinics at our retail stores and through Your
                          Blue Bus
                        </li>
                        <li>
                          Sending communications about the safety and
                          effectiveness of the vaccines
                        </li>
                        <li>Reaching out to high-risk members</li>
                        <li>Sharing information on Latino Public Radio</li>
                        <li>
                          Leading by example with mandatory vaccinations for
                          BCBSRI employees
                        </li>
                      </ul>
                    </div>
                    <div>
                      <p>
                        For both employers and members, we coordinated with
                        government officials to share up-to-date information
                        about COVID-19&ndash;and their benefits&ndash;on our{" "}
                        <a
                          href="https://www.bcbsri.com/keepinformed"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Keeping You Well and Well-Informed
                        </a>{" "}
                        site.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="protecting-members__benefits">
                  <StaticImage
                    src="../images/community/Icon-Covid-Testing.svg"
                    alt="people sharing"
                    layout="fixed"
                    width={58}
                    height={62}
                  />
                  <p className="community-subheader h3--dk-btm-border">
                    Temporary Benefits
                  </p>
                  <StatContainer>
                    <Stat
                      stat={"100"}
                      description={"coverage for COVID-19 testing*"}
                      percentage
                      darkbg
                    />
                    <Stat
                      stat={"100"}
                      description={
                        "coverage for COVID-19 treatment in network*"
                      }
                      percentage
                      darkbg
                    />
                  </StatContainer>
                  <p className="community-disclaimer">
                    *Includes all fully insured plans. Not all self-funded
                    employers have chosen to cover this benefit without cost
                    share.
                  </p>
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="making-play-a-priority">
          <FullWidthContainer
            styleName="community-page-play"
            background={`var(--blue-to-white)`}
          >
            <SectionHeader
              reportType={"community"}
              title={"Making play a priority"}
              intro={
                "The COVID-19 pandemic has taken a toll on the mental and physical health of kids and being active makes a big difference in their well-being. Recess Rocks in Rhode Island—a six-year-old partnership between BCBSRI, Playworks, and the Rhode Island Healthy Schools Coalition—has helped educators pilot new approaches to incorporating safe and healthy play into their school day."
              }
            />
            <NestedContainer
              styleName=""
              className="recess-champions"
              background={`var(--white)`}
              hasShadow
              padded
            >
              <h3>Recess Champions</h3>
              <Row6633 doubleGap>
                <div className="recess-champions__left">
                  <p>
                    In 2021, Recess Rocks started the Recess Champions program,
                    which celebrates educators who have taken steps to
                    prioritize play and social-emotional learning.
                  </p>
                  <ul className="community-list">
                    <li>
                      Coleen Smith, Principal of the Wakefield School in the
                      South Kingstown school district
                    </li>
                    <li>
                      Craig Giarrusso, a physical education teacher at the
                      Nathanael Greene Elementary School in Pawtucket
                    </li>
                  </ul>
                  <StaticImage
                    src="../images/community/Image-Recess-Champions.jpg"
                    alt="Smiling girl holding onto a playground rope."
                  />
                  <a
                    className="community-link"
                    href="https://www.playworks.org/new-england/2021/05/24/recess-rocks-in-ri-prioritizes-rhode-island-students-health-and-well-being-throughout-covid-19-crisis"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read more about the Recess Champions program
                  </a>
                </div>
                <div className="recess-champions__right">
                  <p class="community-subheader h3--btm-border">
                    In the past 6 years
                  </p>
                  <StatContainer>
                    <Stat
                      stat={"101"}
                      description={
                        "RI schools have improved the culture of their recess with Recess Rocks"
                      }
                    />
                    <Stat
                      stat={"37,000"}
                      description={
                        "students reaping the social, emotional, and physical benefits of play"
                      }
                    />
                  </StatContainer>
                </div>
              </Row6633>
              <div style={{ marginTop: "3rem" }}>
                <TestimonialCallout
                  quote="Students and staff have been feeling isolated because of the masks and physical distancing requirements. Recess has given them time to be social and active—it’s the outdoor release and movement break."
                  author="Coleen Smith"
                  role="Principal of the Wakefield School and Recess Rocks Champion"
                />
              </div>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="providing-access-to-healthy-food">
          <FullWidthContainer>
            <SectionHeader
              reportType={"community"}
              title={"Providing access to healthy food"}
              intro={
                "Too many of our neighbors cannot meet their basic food needs: 1 in 6 households, according to the RI Life Index. The risk of hunger is even higher for families with children, where 1 in 4 families do not have enough to eat. We worked with our partners to help address food security during this second year of the pandemic."
              }
            />
            <NestedContainer
              className="healthy-choices"
              background={`var(--color-2)`}
              padded
            >
              <Row6633 reverse verticalCenter>
                <div>
                  <h3>Healthy choices for a food desert</h3>
                  <p>
                    Southside Community Land Trust (SCLT) received a $2.158
                    million loan to support the organization’s work in bringing
                    healthy and affordable food to Central Falls, Pawtucket, and
                    South Providence. We were pleased to contribute a portion of
                    these funds, alongside the Kresge Foundation and other local
                    funders. The loan will finance SCLT’s new headquarters and
                    Farm-to-Market Center in Providence. Along with other
                    programs, SCLT provides leased land to urban and rural
                    farmers and helps them sell their produce in low-income
                    communities.
                  </p>
                </div>
                <div>
                  <StaticImage
                    src="../images/community/Image-Healthy-Choices.jpg"
                    alt="Grocery bags full of vegetables."
                  />
                  <span className="img-caption">
                    Photo: Courtesy of Southside Community Land Trust
                  </span>
                </div>
              </Row6633>
              <div style={{ marginTop: "3rem" }}>
                <TestimonialCallout
                  quote="It takes vision and creativity to make healthy food available in places where it is hard to find. This investment today will improve health outcomes for many years to come."
                  author="Margaret DeVos"
                  role="Executive Director, Southside Community Land Trust"
                />
              </div>
            </NestedContainer>
            <NestedContainer padded styleName="" className="childhood-obesity">
              <Row6633 doubleGap>
                <div>
                  <RowEqual>
                    <div>
                      <StaticImage
                        src="../images/community/Image-Childhood-Obesity.jpg"
                        alt="Smiling girl walking with hiking stick."
                      />
                    </div>
                    <div class="childhood-obesity__improvements">
                      <h3>Improvements in childhood obesity rates</h3>
                      <p>
                        Since 2016, BCBSRI has been partnering with Rhode Island
                        KIDS COUNT to track data on childhood obesity in Rhode
                        Island. This year, Rhode Island KIDS COUNT released data
                        showing that the percentage of children who are
                        overweight or obese dropped from 35% in 2016 to 31% in
                        2019, with the largest decline among Hispanic children.
                        However, that rate is expected to rise due to the impact
                        of COVID-19.
                      </p>
                      <figure className="testimonial">
                        <span className="testimonial__border"></span>
                        <blockquote>
                          “While we have been pleased to see an overall decline
                          in the percentage of overweight and obesity among
                          Rhode Island children over the years, we’re concerned
                          about an increase related to the pandemic and the
                          racial and ethnic disparities that have persisted over
                          time.”
                        </blockquote>
                        <figcaption>
                          <cite>&mdash;&nbsp;Elizabeth Burke Bryant</cite>,
                          Executive Director, Rhode Island KIDS COUNT
                        </figcaption>
                      </figure>
                    </div>
                  </RowEqual>
                </div>
                <div>
                  <p className="community-subheader h3--btm-border">
                    Partnerships to alleviate hunger
                  </p>
                  <StatContainer>
                    <Stat
                      stat={"2,700"}
                      description={
                        "meals delivered to older residents through Meals on Wheels of RI"
                      }
                    />
                    <Stat
                      stat={"800"}
                      description={
                        "meals served through Lunch on Us at McAuley House"
                      }
                    />
                    <Stat
                      stat={"19,000"}
                      description={
                        "raised through our annual fundraiser for the RI Food Bank"
                      }
                      dollarSign
                    />
                  </StatContainer>
                </div>
              </Row6633>
            </NestedContainer>
            <div id="giving-to-our-community">
              <NestedContainer
                styleName=""
                background={`var(--color-17)`}
                padded
              >
                <Row6633 reverse>
                  <div>
                    <p className="community-subheader h3--btm-border">
                      Donated by BCBSRI Employees
                    </p>
                    <StatContainer>
                      <Stat stat={"121,952"} dollarSign />
                    </StatContainer>
                  </div>
                  <div>
                    <h3>Giving to our community</h3>
                    <p>
                      For decades, BCBSRI has partnered with the United Way of
                      Rhode Island to raise funds for their important work. In
                      2021, support of the United Way was critical to providing
                      COVID-19 relief and necessary community support.
                    </p>
                  </div>
                </Row6633>
              </NestedContainer>
            </div>
          </FullWidthContainer>
        </div>
      </div>
    </Layout>
  );
};

export default CommunityPage;
